.slideshow-wrap {
    position: relative;
    background-color: black;
    //#layerslider { display: block; }
    //#layerslider-mobile { display: none; }
    //@media screen and (max-width: $size-slider-breakpoint) {
    //    #layerslider { display: none; }
    //    #layerslider-mobile { display: block; }
    //}

    .slideshow-next {
        right: 50px;
    }
    .slideshow-prev {
        left: 50px;
    }
    .subtitle {
        color: $color-white;
        letter-spacing: 0.3rem;
        text-transform: uppercase;
        font-size: $font-size-24;
        text-align: center;
        padding-bottom:0.5rem;
    }
    .title {
        @extend .font-2-normal;
        text-transform: uppercase;
        color: $color-primary;
        font-size: $font-size-50;
        text-align: center;
    }
    .ls-l a {
        width: auto;
    }

    .caption {
        position: absolute;
        top: 50%;
        left: 32%;
        transform: translateX(-50%);
        z-index: $z-index-slide-caption;
        h2 {
            font-size: $font-size-44;
            font-weight: normal;
            color: $color-white; 
            text-transform: uppercase;
            text-shadow: 0 0 10px $color-black;
        }   
        a.btn-caption {
            background: #007baa;
            padding: 15px;
            width: 25%;
            margin-top: 20px;
            display: block;
            text-align: center;
            transition: 0.3s;
            border-radius: 5px;
            h4 {
                font-weight: normal;
                font-size: $font-size-18;
                color: $color-white;  
                text-transform: uppercase;
                transition: 0.3s;
            }
            &:hover {
                background: #000;
                transition: 0.3s;
                h4 {
                    color: #007baa;
                    transition: 0.3s;
                }
            }
        }
    }

    @media screen and (max-width: $size-xs-max) {
        .ls-l a {
            font-size: $font-size-10;
            border-width: 1px;
            padding: 0.25rem;
        }
    }
    @media screen and (max-width: $size-slider-breakpoint) {
        //.title {
        //    font-size: 26px;
        //}
        //.subtitle {
        //    font-size: 16px;
        //}
        .caption {
            top: $size-nav-height-mobile + 20px;
        }

    }
}

