.btn {
    background-color: #007baa;
    color: $color-white;
    border: none;
    border-radius: 0;
    padding: 5px;
    font-weight: bold;
    font-size: $font-size-30;
    width: 100%;
    transition: all 0.2s ease-in;
    
    &:hover {
        transition: all 0.2s ease-in;
        color: $color-black;
    }
}
