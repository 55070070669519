// IMPORT ADDONS
@import '../vendors/bootstrap/css/bootstrap';
@import '../vendors/font-awesome/font-awesome';

/* purgecss start ignore */
@import '../vendors/owl-carousel2/owl.carousel';
@import '../vendors/magnific-popup/magnific-popup';
// @import '../vendors/cube-portfolio/cubeportfolio';
@import 'styles/alertify';
/* purgecss end ignore */

// IMPORT CUSTOMIZATION & RESET (order is important)
@import 'core/reset';
@import 'variables';
@import 'core/html';
@import 'utilities/functions';

// *******************
// Load basic classes (besoin de rester ici à la fin)
@import 'styles/fonts'; // utility classes for fonts
@import 'utilities/spacing'; // utility classes for spacing
@import 'utilities/flex'; // utility classes for flex box
@import 'utilities/responsive';
@import 'utilities/animation';

@import 'styles/colors'; // utility classes for colors
@import 'styles/hover';
@import 'styles/typography';
@import 'styles/buttons';

/* GENERAL TEMPLATE */
body {
    background-color: $color-black;

    // to fix parallax bug on iphone...
    .parallax {
        background-attachment: fixed;
        @media screen and (max-width: $size-xs-max) {
            background-attachment: scroll;
        }
    }
}

/** Add this class to any anchor tag so it clear the fixed menu height (used in forms) */
.jumptarget::before {
    content: "";
    display: block;
    height: $size-nav-height; /* fixed header height*/
    margin: -$size-nav-height 0 0; /* negative fixed header height */
}

/** Adds a shadow to the div element **/
.shadow {
    position: relative;

    &::after {
        content: '';
        z-index: 0;
        width: 100%;
        top: 100%;
        height: 40px;
        position: absolute;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }
}

h1, h2, h3, h4, h5 {
    font-family: $font-family-1;
}

p {
    font-family: $font-family-2;
    font-size: $font-size-22;
    font-weight: normal;
    color: #c2c2c2;
    margin: 0;
    line-height: 1.5;
}

/** Replace the width of the container **/
.container {
    width: 1550px;
    @media screen and (max-width: 1550px) {
        width: 100%;
        padding: 0 25px;
    }
}

/*****************************************************/
/* Menu (to use with /modules/module-menu.php)
/* Or /modules/module-menu-centre.php
/* Or /modules/module-menu-etage.php
 */
/*****************************************************/
@import 'modules/menu';
// @import 'menu-centre';
// @import 'menu-etage';


/*****************************************************/
/* Footer (to use with /modules/module-footer.php)
/*****************************************************/
@import 'styles/footer';

/*****************************************************/
/* Sections (to use with /sections/section-*-*.php)
/*****************************************************/
//@import 'sections/section-header-page';

//@import 'sections/section-text-image';

@import 'sections/section-bursts';

/*****************************************************/
/* Modules (to use with /modules/module-*-*.php)
/*****************************************************/
@import 'modules/module-slideshow';
@import 'modules/module-header';
@import 'modules/module-soumission';




/*******************************************************************************
* ACCUEIL
*******************************************************************************/

#section-black {
    background: url("../images/accueil_section01_bg.jpg") no-repeat;
    background-repeat: repeat;
    background-size: auto;
    padding: 100px 0 0 0;
    .section-intro {
        h2 {
            font-weight: bold;
            font-size: $font-size-30;
            color: #007baa;
            text-align: center;
            padding-bottom: 35px;
        }
    }
    .section-text-img {
        padding-bottom: 50px;
        .left-side {
            width: 52%;
            margin-right: 5%;
            h2 {
                text-align: initial;
            }
        }
    }
    .section-background {
        background: #007baa;
        padding: 25px 0;
        h3 {
            text-align: center;
            font-weight: bold;
            font-size: $font-size-30;
            color: $color-white;
            text-transform: initial;
        }
    }
    .section-carousel-text {
        padding: 50px 0;
        .left-side {
            width: 49%;
            margin-right: 5%;
            .main-content {
                position: relative;
                .col-xs-12.txt {
                    padding-left: 0;
                }
                .owl-theme {
                    .custom-nav {
                        position: absolute;
                        top: 20%;
                        left: 0;
                        right: 0;

                        .owl-prev, .owl-next {
                            position: absolute;
                            height: 100px;
                            color: inherit;
                            background: none;
                            border: none;
                            z-index: 100;

                            i {
                                font-size: 2.5rem;
                                color: #be1e2d;
                            }
                        }
                    }
                }
                .arrow.prev.next-vedette {
                    position: absolute;
                    left: -53px;
                    top: 45%;
                    z-index: 1;
                }
                .arrow.next.prev-vedette {
                    position: absolute;
                    right: -10px;
                    top: 45%;
                    z-index: 1;
                }
                .arrow {
                    .img-responsive.hover {
                        display: none;
                    }
                    &:hover {
                        .img-responsive.hover {
                            display: block;
                        }
                        .img-responsive.defaut {
                            display: none;
                        }
                    }
                }
            }
        }
        .right-side {
            width: 50%;
            padding-top: 15px;
            .bloc {
                .title {
                    background: #9a9a9a;
                    border-radius: 5px;
                    margin-bottom: 10px;
                    padding: 15px;
                    h4 {
                        font-size: $font-size-22;
                        font-weight: normal;
                        color: #000;
                        text-align: center;
                        span {
                            font-size: $font-size-16; 
                        }
                    }
                }
            }
        }
    }
}

#section-burst {
    padding-bottom: 50px;
    .burst {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        transition: 0.3s;
        h3 {
            font-size: $font-size-30;
            font-weight: normal;
            text-transform: initial;
            color: $color-white;
            text-align: center;
        }
        .btn-burst {
            background: #007baa;
            padding: 15px;
            width: 33%;
            text-align: center;
            border-radius: 5px;
            margin-top: 10px;
            transition: 0.3s;
            h4 {
                font-size: $font-size-18;
                font-weight: normal;
                color: $color-white;
            }
        }
        &:hover {
            .btn-burst {
                background: $color-black;
                transition: 0.3s;
                h4 {
                    color: #007baa;
                }
            }
        }
    }
    .burst.left {
        background: url("../images/accueil_section03_burst01.jpg") no-repeat;
        height: 248px;
        &:hover {
            background: url(../images/accueil_section03_burst01_hover.jpg) no-repeat;
        }
    }
    .burst.center {
        background: url("../images/accueil_section03_burst02.jpg") no-repeat;
        height: 248px;
        &:hover {
            background: url(../images/accueil_section03_burst02_hover.jpg) no-repeat;
        }
    }
    .burst.right {
        background: url("../images/accueil_section03_burst03.jpg") no-repeat;
        height: 248px;
        &:hover {
            background: url(../images/accueil_section03_burst03_hover.jpg) no-repeat;
        }
    }
    .burst.contact {
        background: url("../images/services_section03_burst01.jpg") no-repeat;
        height: 248px;
        &:hover {
            background: url(../images/services_section03_burst01_hover.jpg) no-repeat;
        }
    }
}

#section-footer {
    background: #007baa;
    padding: 25px 0;
    .left-side {
        width: 100%;
        a.tel {
            .icon {
                margin-right: 15px;
                .img-responsive.hover {
                    display: none;
                }
            }
            .text {
                width: 100%;
                h4 {
                    font-weight: normal;
                    font-size: $font-size-16;
                    color: $color-white;
                }
            }
            &:hover {
                img.img-responsive.hover {
                    display: block !important;
                }
                img.img-responsive.defaut {
                    display: none !important;
                }
                h4 {
                    color: $color-black;
                }
            }
        }
    }
    .right-side {
        a {
            .img-responsive.hover {
                display: none;
            }
            &:hover {
                .img-responsive.hover {
                    display: block;
                }
                .img-responsive.defaut {
                    display: none;
                }
            }
        }
    }
}

/*******************************************************************************
* SERVICES
*******************************************************************************/

#section-black {
    .section-img-text {
         padding-bottom: 50px;
        .left-side {
            width: 50%;
            margin-right: 5%;
            h2 {
                text-align: initial;
            }
        }
        .right-side {
            width: 50%;
        }
    }
}

/*******************************************************************************
* RÉALISATIONS
*******************************************************************************/

#section-black {
    .section-realisations {
        padding-bottom: 50px;
        a.item {
            width: 33.3333%;
            padding: 0 10px 65px 10px;
            h3 {
                text-transform: initial;
                font-weight: bold;
                font-size: $font-size-30;
                color: #007baa;
            }
            p {
                line-height: 1.3;
            }
            .image {
                position: relative;
                .overlay {
                    background-color: rgba(0, 0, 0, 0.5);
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    opacity: 1;
                    transition: all 0.3s ease-out;
                }
            }
            &:hover {
                .overlay {
                    background-color: rgba(168,189,221, 0.5);
                    transition: all 0.3s ease-out;
                }
            }
        }
    }
}

/*******************************************************************************
* SOUMISSION
*******************************************************************************/







/*******************************************************************************
* COORDONNÉES
*******************************************************************************/

#section-black {
    .section-coordonnees {
        padding-bottom: 100px;
        .left-side {
            width: 50%;
            margin-right: 5%;
            .icon {
                width: 17%;
            }
            h3, h4 {
                font-weight: bold;
                font-size: $font-size-30;
                color: $color-white;
                text-transform: initial;
            }
            h4 {
                color: #007baa;
                text-transform: uppercase;
                padding-bottom: 15px;
            }
            h5 {
                font-weight: bold;
                font-size: $font-size-24;
                color: $color-white;
                a {
                    font-weight: bold;
                    font-size: $font-size-28;
                    color: #007baa;
                    &:hover {
                        color: #fff !important;
                    }
                }
            }
            p {
                line-height: 1.3;
            }
            .jours-heures {
                display: flex;
                .jour {
                    width: 45%;
                }
            }
        }
        .right-side {
            width: 50%;
        }
    }
}

#content {
    h3#firstHeading {
        color: #007baa;
    }
    p a {
        color: $color-black;
    }
}





/*******************************************************************************
* MOBILE OVERRIDES FOR ALL SECTIONS
*******************************************************************************/

@media screen and (max-width: 1755px) {
   
.slideshow-wrap .caption {
    top: 50%;
    left: 38%;
}
}

@media screen and (max-width: 1635px) {
   
#section-black .section-carousel-text .left-side .main-content .arrow.prev.next-vedette, #section-black .section-carousel-text .left-side .main-content .arrow.next.prev-vedette {
    display: none;
}
}

@media screen and (max-width: 1600px) {

.slideshow-wrap .caption {
    top: 50%;
    left: 35%;
}   
#section-footer {
    padding: 25px;
}
#section-footer .left-side {
    width: 80%;
}
}

@media screen and (max-width: 1560px) {

nav.module-menu .menu-navbar .wrapper.right .main-menu > ul li div {
    font-size: 0.9rem;
}
nav.module-menu .menu-navbar .wrapper.left .logo {
    max-width: 200px;
}
nav.module-menu .menu-navbar .wrapper.right .section-tel ul li.phone a div {
    font-size: 1.2rem;
}
nav.module-menu .menu-navbar .wrapper.right .section-tel {
    margin-bottom: 25px;
}
}

@media screen and (max-width: 1484px) {

#section-black .section-formulaire .btn-submit {
    min-width: 400px !important;
}
#section-black .section-formulaire form .buttons {
    margin-left: 30px;
}
}

@media screen and (max-width: 1415px) {
   
.module-header--caption {
    left: 5%;
}
#section-black .section-formulaire form .buttons {
    margin-left: 40px;
}
}

@media screen and (max-width: 1400px) {

nav.module-menu .menu-navbar .wrapper.right .main-menu > ul li div {
    font-size: 0.8rem;
}
nav.module-menu .menu-navbar .wrapper.right .section-tel ul li.phone a div {
    font-size: 1.1rem;
}
nav.module-menu .menu-navbar .wrapper.right .section-tel {
    margin-bottom: 20px;
}
.slideshow-wrap .caption a.btn-caption {
    width: 30%;
}
}

@media screen and (max-width: $size-md-max) {
   
nav.module-menu .menu-navbar .wrapper.right .section-tel {
    margin-bottom: 15px;
}
nav.module-menu .menu-navbar .wrapper.right .section-tel ul li.phone a img:nth-child(2) {
    bottom: 16px;
}
nav.module-menu .menu-navbar .wrapper.left .logo {
    max-width: 175px;
}
#section-black .section-formulaire form .buttons {
    margin-left: 8%;
}
}

@media screen and (max-width: 1210px) {
   
nav.module-menu .menu-navbar .wrapper.right .section-tel, nav.module-menu .menu-navbar .wrapper.right .main-menu > ul, nav.module-menu .menu-navbar .wrapper.right .section-facebook {
    display: none;
}
nav.module-menu .menu-toggle {
    position: absolute;
    right: 0;
}
}

@media screen and (max-width: 1024px) {
    
nav.module-menu .menu-navbar .wrapper.left .logo {
    max-width: 120px;
    margin-left: 15px;
}
nav.module-menu .menu-navbar {
    height: 130px;
}
nav.module-menu .menu-navbar .wrapper.right .section-rbq {
    display: none;
}
.slideshow-wrap .caption {
    top: 50%;
    left: 35%;
}
.slideshow-wrap .caption a.btn-caption {
    width: 40%;
}
.module-header--caption {
    left: 3%;
}
#section-burst .burst .btn-burst {
    width: 45%;
}
#section-burst .burst.left, #section-burst .burst.center, #section-burst .burst.right, #section-burst .burst.contact {
    background-position: center;
}
#section-black .section-realisations a.item {
    width: 50%;
}
#section-black .section-coordonnees {
    flex-direction: column;
}
#section-black .section-coordonnees .left-side {
    width: 100%;
    margin: 0 0 35px 0;
}
#section-black .section-coordonnees .right-side {
    width: 100%;
}
#section-black .section-coordonnees .right-side #map-canvas {
    height: 400px !important;
}
#section-black .section-coordonnees .left-side .icon {
    width: 14%;
}
#section-black .section-formulaire .col-sm-push-2 {
    left: 5%;
}
#section-black .section-formulaire .col-sm-8 {
    width: 85%;
}
#section-black .section-formulaire .btn-submit {
    min-width: 350px !important;
}
#section-black .section-formulaire form .buttons {
    margin-left: 6%;
}
}

@media screen and (max-width: $size-sm-max) {
 
nav.module-menu .menu-toggle .word {
    font-size: 18px;
}  
#section-black .section-text-img, #section-black .section-carousel-text .container, #section-burst .container, #section-black .section-img-text {
    flex-direction: column;
} 
#section-black .section-text-img .left-side, #section-black .section-carousel-text .left-side, #section-black .section-img-text .left-side {
    width: 100%;
    margin: 0 0 35px 0;
}
#section-black .section-background h3 {
    font-size: 1.3rem;
}
#section-black .section-carousel-text .right-side, #section-black .section-img-text .right-side {
    width: 100%;
}
#section-burst .burst .btn-burst {
    width: 20%;
}
#section-black .section-coordonnees .left-side .icon {
    width: 15%;
}
#section-black .section-formulaire .col-sm-8 {
    width: 100%;
}
#section-black .section-formulaire .col-sm-push-2 {
    left: 0%;
}
#section-black .section-formulaire .btn-submit {
    min-width: 295px !important;
}
#section-black .section-formulaire form .buttons {
    margin-left: 9%;
}
}

@media screen and (max-width: $size-xs-max) {
 
nav.module-menu .menu-navbar .wrapper.left .logo {
    max-width: 110px;
    margin-top: 5px;
    margin-left: 10px;
}
nav.module-menu .menu-toggle .word {
    font-size: 14px;
}
nav.module-menu .menu-navbar .wrapper.right .main-menu {
    width: 150%;
}
.slideshow-wrap .caption h2 {
    text-shadow: 0 0 10px $color-black;
}
.slideshow-wrap .caption a.btn-caption {
    width: 70%;
}
nav.module-menu .menu-navbar {
    height: 105px;
}
.module-header--caption {
    left: 4%;
    top: 90%;
}
#section-burst .burst .btn-burst {
    width: 35%;
}
#section-black .section-text-img .left-side h2 {
    text-align: center;
}
#section-black {
    padding: 65px 0 0 0;
}
#section-black .section-background {
    padding: 25px 5%;
}
#section-black .section-realisations a.item {
    width: 100%;
    padding: 0 0 35px 0;
}
#section-black .section-coordonnees .left-side .bloc {
    flex-direction: column;
}
#section-black .section-coordonnees .left-side .icon {
    width: 15%;
    margin: auto;
    padding-bottom: 15px;
}
#section-black .section-coordonnees .left-side .info {
    width: 100%;
}
#section-black .section-formulaire .col-sm-push-2 {
    padding: 0;
}
#section-black .section-formulaire form .buttons {
    margin-left: 0;
}
}

@media screen and (max-width: 360px) {

.slideshow-wrap .caption {
    top: 55%;
    left: 53%;
    width: 100%;
}
#section-black .section-coordonnees .left-side h5 a {
    font-size: 1.15rem;
}
#section-black .section-formulaire .btn-submit {
    min-width: 290px !important;
}
}